<template>
  <div>
    <div class="banner">
      <el-carousel :autoplay="false" arrow="always" height="500px">
        <el-carousel-item v-for="(item, index) in pics" :key="index">
          <a :href="item.url" target="_blank"><img :src="item.img" alt="" style="object-fit: cover;width:100%;height: 100%"/></a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="bannerpc">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in pic" :key="index">
          <a :href="item.url" target="_blank"><img :src="item.img" alt="" style="object-fit: cover;width:100%;"/></a>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pics: [{
        img: require("../assets/img/ban4.jpg")
      },
        {
          img: require("../assets/img/ban1.webp"),
          url: 'https://yp.bzkuajing.com/'
        },
        {
          img: require("../assets/img/ban2.webp"),
          url: 'https://kj.bzkuajing.com/'
        },
        {
          img: require("../assets/img/ban3.webp"),
          url: 'https://portal.bzbpd.com.cn:8002/#/index'
        },
      ],
      pic: [{
        img: require("../assets/img/pban1.webp"),
        url: 'https://yp.bzkuajing.com/'
      },
        {
          img: require("../assets/img/pban2.webp"),
          url: 'https://kj.bzkuajing.com/'
        },
        {
          img: require("../assets/img/pban3.webp"),
          url: 'https://portal.bzbpd.com.cn:8002/#/index'
        },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.bannerpc {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .banner {
    display: none;
  }

  .bannerpc {
    display: block;
  }
}
</style>
