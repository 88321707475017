<template>
  <div class="container">
    <Nav></Nav>
      <Swiper></Swiper>
      <div class="one-two">
          <el-row :gutter="20" >
              <el-col :xs="24" :sm="24" :md="24" :lg="12" style="display: flex;justify-content: center;">
                  <img src="../../src/assets/img/bmtptrk.jpg" @click="clickImg('http://csp.qingyuntong.com.cn/login?redirect=/app_main/index')" class="ot-img" />
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="12" style="display: flex;justify-content: center;">
                  <img src="../../src/assets/img/bmylrk.jpg" @click="clickImg('https://portal.bzbpd.com.cn:8002/#/login?type=login')" class="ot-img" />
              </el-col>
          </el-row>
      </div>
    <div class="one">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="6" :lg="6">
          <div class="one-box">
            <img src="../assets/img/wangshang.webp" alt="">
            <div class="one-text">
              <div class="one-text-t">
                网上办事
              </div>
              <p>Online service</p>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6">
          <div class="one-item"
               onclick="window.open('https://app.singlewindow.cn/userserver/user/userEtpsRegister/choseRgtWay')">
            <div class="one-top">
              <img src="../assets/img/Slice 1.png" alt="">
              <p>企业进驻</p>
              <div>
                Enterprise entry
              </div>
            </div>
            <div class="one-top"
                 onclick="window.open('https://app.singlewindow.cn/cas/oauth2.0/authorize?client_id=sd00000000X4dW0P007&response_type=code&redirect_uri=https://www.singlewindow.sd.cn/pmc/reg/new/silentLogin?jumpTo=https://www.singlewindow.sd.cn/portal/delivery/sdisp')">
              <img src="../assets/img/Slice 4.png" alt="">
              <p>跨境结算</p>
              <div>
                Cross-border settlement
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6">
          <div class="one-item">
            <div class="one-top"
                 onclick="window.open('https://app.singlewindow.cn/cas/oauth2.0/authorize?client_id=sd00000000X4dW0P007&response_type=code&redirect_uri=https://www.singlewindow.sd.cn/pmc/reg/new/silentLogin?jumpTo=https://www.singlewindow.sd.cn/portal/delivery/cebclearance_bz')">
              <img src="../assets/img/Slice 2.png" alt="">
              <p>通关服务</p>
              <div>
                Customs services
              </div>
            </div>
            <div class="one-top"
                 onclick="window.open('https://app.singlewindow.cn/cas/oauth2.0/authorize?client_id=sd00000000X4dW0P007&response_type=code&redirect_uri=https://www.singlewindow.sd.cn/pmc/reg/new/silentLogin?jumpTo=https://www.singlewindow.sd.cn/portal/delivery/cebdxp_bz')">
              <img src="../assets/img/Slice 5.png" alt="">
              <p>备案登记</p>
              <div>
                registration
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6">
          <div class="one-item">
            <div class="one-top"
                 onclick="window.open('https://app.singlewindow.cn/cas/oauth2.0/authorize?client_id=sd00000000X4dW0P007&response_type=code&redirect_uri=https://www.singlewindow.sd.cn/pmc/reg/new/silentLogin?jumpTo=https://www.singlewindow.sd.cn/portal/delivery/cebtax_bz')">
              <img src="../assets/img/Slice 3.png" alt="">
              <p>税务服务</p>
              <div>
                Tax service
              </div>
            </div>
            <div class="one-top" onclick="window.open('https://datav.singlewindow.sd.cn/share/a835300d-8ade-4c41-b9c3-101936fbb662')">
              <img src="../assets/img/Slice 6.png" alt="">
              <p>查询服务</p>
              <div>
                inquiry service
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

    </div>
      
      
      <div class="five">
          <div class="title">
              新闻资讯
          </div>
          <div class="stitle">
              News information
          </div>
          <div class="five-box" >
              <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%">
                  <el-tab-pane label="政策文件" name="02">
                  </el-tab-pane>
                  <el-tab-pane label="综试区动态" name="03">
                  </el-tab-pane>
                  <el-tab-pane label="通知公告" name="01">
                  </el-tab-pane>
                  <div class="five-item" v-if="homeNoticeList.length">
                      <el-row :gutter="20">
                          <el-col :xs="24" :sm="24" :md="activeName !== '02' ? 24 : 13" :lg="9" :xl="8">
                              <div class="five-left">
                                  <img :src="homeNoticeList[0].noticeImg" alt="" @click="clickNew(homeNoticeList[0])">
                              </div>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="4" v-if="activeName !== '02'">
                              <div class="five-center five-center1">
                                  <img :src="homeNoticeList[1].noticeImg" alt="" @click="clickNew(homeNoticeList[1])">
                              </div>
                              <div class="five-center">
                                  <img :src="homeNoticeList[2].noticeImg" alt="" @click="clickNew(homeNoticeList[2])">
                              </div>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="activeName !== '02' ? 16 : 11" :lg="activeName !== '02' ? 11 :15" :xl="activeName !== '02' ? 12 :15">
                              <div class="five-right" @click="clickNew(homeNoticeList[1])">
                                  <div class="five-top">
                                      <div class="l"><a
                                          target="_blank">{{ homeNoticeList[1].noticeTitle }}</a>
                                      </div>
                                      <div class="time">{{ homeNoticeList[1].noticeDate }}</div>
                                  </div>
                                  <p>{{ homeNoticeList[1].noticeSubtitle }}
                                  </p>
                              </div>
                              <div class="five-right five-right1" @click="clickNew(homeNoticeList[2])">
                                  <div class="five-top">
                                      <div class="l"><a
                                          target="_blank">{{ homeNoticeList[2].noticeTitle }}</a></div>
                                      <div class="time">{{ homeNoticeList[2].noticeDate }}</div>
                                  </div>
                                  <p>{{ homeNoticeList[2].noticeSubtitle }}
                                  </p>
                              </div>
                              
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
                              <div style="height: 10px;margin-bottom: 20px;">
                                  <p @click="$router.push(`/news?type=${activeName}`)" class="more">更多<i class="el-icon-d-arrow-right"></i> </p>
                              </div>
                          </el-col>
                      </el-row>
                  </div>
                  <div style="width: 100%;min-width: 80%; min-height: 450px;" v-else>
                      <p>暂无数据</p>
                  </div>
              </el-tabs>
              
          </div>
      </div>
      
    <div class="two">
      <div class="title">
        六大体系
      </div>
      <div class="stitle">
        Six systems
      </div>
      <div class="two-box">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <div class="tow-item">
              <img src="../assets/img/tixi.png" alt="">

            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <div class="right">
              <div>
                全球领先的一站式跨境贸易供应链服务平台
              </div>
              <p class="p-indentation">
                以综合监管和服务为导向，以滨州跨境电子商务公共服务平台为依托，探索建立与完善适应跨境电子商务发展的管理制度、法规体系、标准规范体系和服务体系。
                同时推动滨州本地跨境电商综合服
                务平台的发展，实现跨境电子商务业务的便利化、高效化、可监管化、可溯源化，为跨境电子商务产业链各个环节提供跨行业、跨区域、规范化、阳光化、多功能服务的跨境电子商务综合服务环境，从而建立完整的跨境电子商务综合监管服务体系。
              </p>
            </div>
          </el-col>

        </el-row>
      </div>
    </div>
    <div class="three">
      <div class="title">
        综合服务
      </div>
      <div class="stitle">
        integrated service
      </div>
      <div class="three-box">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <div class="three-item">
              <div class="img">
                <img src="../assets/img/haiwc.webp" alt="">
                <div class="three-text">
                  <p><a href="https://wzf.bzkuajing.com/" target="_blank">滨贸通</a></p>
                  <div>Foreign trade integrated services</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <div class="three-item">
              <div class="img">
                <img src="../assets/img/kuajing.webp" alt="">
                <div class="three-text">
                  <p><a href="https://portal.bzbpd.com.cn:8002/#/index"
                        target="_blank">滨贸云链</a></p>
                  <div>Bin trade cloud chain</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <div class="three-item">
              <div class="img">
                <img src="../assets/img/jinrong.webp" alt="">
                <div class="three-text">
                  <p><a href="https://erp.bzkuajing.com/" target="_blank">滨滨跨境</a></p>
                  <div>Binbin cross border</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <div class="three-item">
              <div class="img">
                <img src="../assets/img/wuliu.webp" alt="">
                <div class="three-text">
                  <p><a href="https://yp.bzkuajing.com/" target="_blank">滨州优品</a></p>
                  <div>Binzhou Premium</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="four">
      <div class="four-box">
        <div class="title">
          平台介绍
        </div>
        <div class="stitle">
          Platform introduction
        </div>
        <p class="p-indentation">
          中国（滨州）跨境综试区公服平台（简称“公服平台”）是在滨州市商务局联合滨州海关、滨州税务、滨州外管等相关部门的管理指导下，由滨州市全资国有企业与中国电子口岸数据中心合作共建运营的跨境电商产业发展“线上公共基础设施”。
          公服平台作为海关三级数据传输节点，在为跨境电商通关环节提供数据申报和数据交换服务的基础上，还提供智慧政务建设、政企协调沟通、企业孵化培训、行业资源对接、业务咨询服务、技术保障支持以及大数据技术应用等一站式跨境电商全产业链综合服务。
          公服平台致力于助推滨州打造一流跨境电商营商环境，营造优质跨境电商生态体系，助推滨州经济社会数字化高质量发展。平台支持的跨境业务模式：1210进出口
          9610进出口 9710出口以及9810出口。
        </p>
      </div>
    </div>
    <div class="six">
      <div class="title">
        企业入驻
      </div>
      <div class="stitle">
        Settled enterprises
      </div>
      <div class="six-box">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <div class="six-item">
              <img src="../assets/img/qy1.webp" alt="">
              <p>滨州跨境电商运营中心</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <div class="six-item">
              <img src="../assets/img/qy2.webp" alt="">
              <p>滨州数字产业贸易区</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <div class="six-item">
              <img src="../assets/img/qy3.webp" alt="">
              <p>滨州保税物流区</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="six-bottom">
        <img src="../assets/img/zuo.png" alt="" style="margin-right: 20px;">
        <img src="../assets/img/you.png" alt="">
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Bottom from '@/components/Bottom.vue'
import Swiper from '@/components/Swiper.vue'
import {queryNoticeList} from "@/apis/notic";
import zcwj from '/src/assets/img/zcwj.webp'

export default {
  components: {
    Nav,
    Bottom,
    Swiper
  },
  data() {
    return {
        activeName : '02',
        homeNoticeList : [],
    }
  },
  methods: {
    handleClick(tab, event) {
      this.getHomeNotice()
    },

    clickImg(url) {
      window.open(url,'_blank')
    },
      
      clickNew(oneNew){
        if (oneNew.noticeImg === zcwj){
            this.$router.push(`/news?type=02`)
        }else{
            localStorage.setItem('oneNew', JSON.stringify(oneNew))
            this.$router.push(`/oneNew?type=${this.activeName}`)
        }
      },
    getHomeNotice() {
        queryNoticeList({noticeType : this.activeName,pageNumber : 1,pageSize : 3}).then((res) => {
            this.homeNoticeList = Object.assign([],res.data.rows)
            if (this.activeName === '02'){
                this.homeNoticeList[0].noticeImg = zcwj
                this.homeNoticeList[1] = res.data.rows[0]
                this.homeNoticeList[2] = res.data.rows[1]
            }
        })
    }
  },
    
    created() {
      this.getHomeNotice()
    }
}
</script>

<style scoped lang="scss">

.container {
  overflow: hidden;

  .p-indentation {
    text-indent: 2em;
  }

  .title {
    font-size: 28px;
    font-weight: 800;
    color: #313131;
    text-align: center;
  }

  .stitle {
    font-size: 16px;
    font-weight: 500;
    color: #999999;
    text-align: center;
    margin-top: 10px;
  }

  .one {
    background-image: url(../assets/img/bj2.png);
    background-repeat: no-repeat;
    background-size: 100% 440px;
    padding-top: 50px;
    display: flex;
    justify-content: center;

    .one-box {
      position: relative;

      .one-text {
        position: absolute;
        top: 30px;
        left: 30px;

        .one-text-t {
          font-size: 24px;
          font-weight: 800;
          color: #FFFFFF;
        }

        p {
          font-size: 14px;
          font-weight: 800;
          color: #FFFFFF;
        }
      }
    }

    .one-item {
      .one-top {
        width: 330px;
        height: 160px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: #999999;
        cursor: pointer;
        transition: all 1s;

        p {
          font-size: 16px;
          font-weight: 500;
          color: #0C3A97;
          margin: 10px 0 5px 0;

          a {
            color: #0C3A97;
          }
        }
      }

      .one-top:hover {
        transform: translateY(-5px);
        box-shadow: 2px 5px 5px #ccc;
      }
    }
  }
    .one-two{
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        background: #F8F8F8;
        .ot-img{
            cursor: pointer;
        }
    }
  .two {
    padding-top: 50px;
    background: #F8F8F8;

    .two-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;

      .two-item {

        img {
          width: 600px;
        }
      }

      .right {
        width: 600px;
        height: 465px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 50px;

        div {
          font-size: 24px;
          font-weight: bold;
          color: #313131;
          margin-bottom: 24px;
        }

        p {
          font-size: 16px;
          font-weight: 800;
          color: #313131;
          line-height: 32px;
        }
      }
    }
  }

  .three {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .three-item {

      width: 330px;
      height: 400px;
      margin-top: 40px;

      .img {
        width: 330px;
        height: 400px;
        position: relative;
        cursor: pointer;
        transition: all 1s;

        img {
          width: 330px;
          height: 400px;
        }

        .three-text {
          position: absolute;
          left: 20px;
          bottom: 20px;


          p {
            font-size: 20px;
            font-weight: bold;
            color: #FFFFFF;
          }

          div {
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }

      .img:hover {
        transform: translateY(-5px);
        box-shadow: 2px 5px 5px #ccc;
      }
    }
  }

  .four {
    height: 700px;
    background-image: url(../assets/img/bj4.webp);
    background-repeat: no-repeat;
    background-size: 100% 700px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    .four-box {
      width: 1320px;
      height: 400px;
      background: rgba(0, 0, 0, 0.6);
      padding: 40px 112px 92px 76px;

      .title {
        color: #FFFFFF;
      }

      .stitle {
        color: #FFFFFF;
      }

      p {
        font-size: 16px;
        color: #FFFFFF;
        margin-top: 28px;
        line-height: 40px;
      }
    }
  }

  .five {
      margin: auto;
    padding-top: 40px;
    width: 70%;
    .five-box {
        width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 32px;

      .five-item {
          width: 100%;
        display: flex;
        justify-content: center;

        .five-left {
            max-width: 100%;
          width: 450px;
            aspect-ratio: 1;
          overflow: hidden;

          img {
              max-width: 100%;
            width: 450px;
              aspect-ratio: 1;
            transition: all 1s;
            cursor: pointer;
              object-fit: cover;
          }

          img:hover {
            transform: translate(0px, 0px) scale(1.1) rotate(0deg);
          }
        }

        .five-center {
            max-width: 100%;
          width: 200px;
            aspect-ratio: 1;
          overflow: hidden;
          cursor: pointer;

          img {
              max-width: 100%;
            width: 200px;
              aspect-ratio: 1;
              object-fit: cover;
            transition: all 1s;
          }

          img:hover {
            transform: translate(0px, 0px) scale(1.1) rotate(0deg);
          }
        }

        .five-center1 {
          margin-bottom: 40px;
        }

        .five-right {
          width: 100%;
          min-height: 160px;
            cursor: pointer;
          .five-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            .l {
              width: 377px;
              font-size: 20px;
              font-weight: bold;
              color: #313131;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;

              a {
                color: black;
              }
            }

            .time {
              font-size: 16px;
              font-weight: 500;
              color: #666666;
            }
          }

          p {
            font-size: 16px;
            font-weight: 500;
            color: #666666;
            line-height: 24px;
          }
        }

        .five-right1 {
          padding-top: 80px;
        }
      }
    }
  }

  .six {
    // background-image: url(../assets/img/bj3.webp);
    // background-repeat: no-repeat;
    // background-size: 100% 470px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    background-color: #ececec;

    .six-box {
      margin-top: 41px;

      .six-item {
        width: 420px;
        height: 356px;
        background: #fff;
        cursor: pointer;
        transition: all 1s;

        img {
          width: 420px;
          height: 280px;
        }

        p {
          font-size: 20px;
          font-weight: bold;
          color: #313131;
          text-align: center;
          margin-top: 20px;
        }
      }

      .six-item:hover {
        transform: translateY(-5px);
        box-shadow: 2px 5px 5px #ccc;
      }
    }

    .six-bottom {
      margin-top: 50px;

      img {
        cursor: pointer;
      }
    }
  }
}


@media only screen and (max-width: 1440px) {
  .container {
    .top {
      .left {
        margin-right: 809px !important;
      }
    }

    .nav {
      .limg {
        margin-right: 0px !important;
      }
    }

    .one {
      .one-box {
        img {
          width: 300px;
        }
      }

      .one-item {
        .one-top {
          width: 300px;
          height: 144px;
        }
      }
    }

    .two {
      .two-item {

        img {
          width: 500px;
        }
      }

      .right {
        width: 500px;
        margin-left: 20px !important;
      }
    }

    .three {
      .three-item {
        width: 300px;
        height: 330px !important;

        img {
          width: 300px !important;
          height: 330px;
        }
      }
    }

    .four {
      .four-box {
        width: 1150px;
      }
    }

    .five {
      .five-box {
        .five-item {
          .five-left {
            width: 500px;

            img {
              width: 500px;
            }
          }

          .five-center {
            width: 200px;

            img {
              width: 200px;
            }
          }

          .five-right {
            width: 500px;
          }
        }
      }
    }


  }
  
}

.more{
    float: right;
    font-size: 15px;
    cursor: pointer;
}

@media only screen and (max-width: 1280px) {
  .container {
    .top {
      .left {
        margin-right: 600px !important;
      }
    }

    .nav {
      .limg {
        margin-right: 0px !important;
      }

      .rimg {
        display: none;
      }

      .bottom {
        li {
          margin-right: 50px;
        }
      }
    }

    .one {
      .one-box {
        img {
          width: 250px;
        }
      }

      .one-item {
        .one-top {
          width: 250px;
          height: 117px;
        }
      }
    }

    .two {
      .two-box {
        .tow-item {
          width: 100%;
          text-align: center;
        }

        .right {
          width: 100%;
          height: 350px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 100px;
        }
      }

    }

    .three {
      .three-item {
        width: 250px;
        height: 300px !important;

        .img {
          width: 250px !important;
          height: 300px !important;

          img {
            width: 250px !important;
            height: 300px !important;
          }
        }


      }
    }

    .four {
      .four-box {
        width: 950px;

        p {
          line-height: 30px;
        }
      }
    }

    .five {
      .five-box {
        .five-item {
          .five-left {
            width: 400px !important;

            img {
              width: 400px !important;
            }
          }

          .five-center {
            width: 160px;

            img {
              width: 160px;
            }
          }

          .five-right {
            width: 400px;

            .l {
              width: 250px !important;
            }
          }
        }
      }
    }

    .six {
      .six-item {
        width: 330px !important;

        img {
          width: 330px !important;
        }
      }
    }


  }
}

@media only screen and (max-width: 1024px) {
  .container {
    .top {
      display: none;

    }

    .nav {
      .ptitle {
        display: block;
      }

      .limg {
        margin-right: 0px !important;
      }

      .rimg {
        display: none;
      }

      .bottom {
        align-items: center;
        justify-content: space-around;

        li {
          margin-right: 50px;
        }

        .ptitle {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
        }

        .el-icon-s-unfold {
          font-size: 30px;
          color: #fff;
        }
      }

      .pnav {
        display: none;
      }

      .nav-icon {
        display: block;
      }
    }

    .banner {
      display: none;
    }

    .bannerpc {
      display: block;
    }

    .one {
      background-image: none;
      background-color: #F8F8F8;


      .one-box {
        text-align: center;
        margin-bottom: 30px;

        img {
          width: 350px;
        }
      }

      .one-item {


        .one-top {
          margin: auto;
          margin-bottom: 30px;
          width: 350px;
          height: 170px;
        }
      }
    }

    .two {

      .two-box {
        .tow-item {
          width: 100%;
          text-align: center;

          img {
            width: 370px !important;
          }
        }

        .right {
          width: 100%;
          // height: 350px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          margin-left: 0 !important;

          div {
            font-size: 16px;
          }

          p {
            font-size: 14px;
          }
        }
      }

    }

    .three {
      .three-item {
        width: 350px;
        height: 400px !important;
        margin: auto;

        .img {
          margin-top: 30px;
          width: 350px !important;
          height: 400px !important;

          img {
            width: 350px !important;
            height: 400px !important;
          }
        }


      }
    }

    .four {
      background-image: none;
      background-color: #00aaff;
      height: auto;
      padding: 20px 0;

      .four-box {
        width: 350px;
        height: auto;
        padding: 40px 20px;

        p {
          line-height: 30px;
        }
      }
    }

    .five {
      height: auto;
      padding: 0 10px;

      .five-box {
        .five-item {
          .five-left {
            width: 360px !important;
            text-align: center;
            margin: auto;

            img {
              width: 360px !important;
            }
          }

          .five-center {
            width: 160px;
            display: none;

            img {
              width: 160px;
            }
          }

          .five-right {
            margin: auto;
            width: 360px;

            .l {
              width: 250px !important;
            }

            p {
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }

    .six {
      .six-item {
        margin: auto;
        width: 370px !important;
        margin-bottom: 30px;

        img {
          width: 370px !important;
        }
      }

      .six-bottom {
        display: none;
      }
    }
  }
}
</style>
