import request from '@/utils/http'
import {encryptionPlus} from '@/utils/encryption'

export function queryNoticeList(data) {
    const returnJson = encryptionPlus(data);
    return request({
        url: `/system/notice/feList`,
        method: 'post',
        data: returnJson,
    })
}
