<template ref="page">
	<div class="container">
		<Nav></Nav>
        <Swiper></Swiper>
		<div class="content">
            <div v-html="oneNotice.noticeContent"></div>
		</div>
		<Bottom></Bottom>
	</div>
</template>
<script>
	import Nav from '@/components/Nav.vue'
	import Bottom from '@/components/Bottom.vue'
    import Swiper from "@/components/Swiper.vue";
	export default {
		components: {
            Swiper,
			Nav,
			Bottom,
		},
		data() {
			return {
                activeName : '01',
                oneNotice : {},
			}
		},
        created() {
            this.getOneNotice()
        },
        methods: {
            getOneNotice() {
                this.oneNotice = JSON.parse( localStorage.getItem('oneNew'))
            }
        }
	}
</script>
<style lang="scss" scoped>
	.container {
		// overflow-x: hidden;

		.content {
			width: 75%;
            margin: 50px auto 50px auto;
		}
  
	}
</style>
