<template ref="page">
	<div class="container">
		<Nav></Nav>
		<Swiper></Swiper>
		<div class="content">
            <el-tabs v-model="activeName" @tab-click="handleClick" style="width:65%">
                <el-tab-pane label="入驻指引" name="01"></el-tab-pane>
                <el-tab-pane label="前置资质" name="02"></el-tab-pane>
                <el-tab-pane label="入驻信息管理" name="03"></el-tab-pane>
                <el-tab-pane label="系统对接" name="04"></el-tab-pane>
                <el-tab-pane label="下载中心" name="05"></el-tab-pane>
            </el-tabs>
            <div class="title">{{oneJoin.title}}</div>
            <div class="text">
                <div v-html="oneJoin.text">
                </div>
            </div>
		</div>
		<Bottom></Bottom>
	</div>
</template>
<script>
	import Nav from '@/components/Nav.vue'
	import Bottom from '@/components/Bottom.vue'
	import Swiper from '@/components/Swiper.vue'
	export default {
		components: {
			Nav,
			Bottom,
			Swiper
		},
		data() {
			return {
                activeName : '01',
                oneJoin : {
                    title : '入驻指引',
                    text : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 大致流程：前置资质办理—综服平台用户注册—企业入驻—系统对接上线<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;前置资质办理：<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;完成海关注册登记、中国电子口岸IC卡办理、跨境电商企业类型备案等前置资质办理。（详见企业入驻-前置资质办理介绍）<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;综服平台用户注册:<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业可在综服平台用户登录界面，点击立即注册，提交企业开户信息完成平台用户注册。<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业入驻：<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业完成平台用户注册后，登录入驻信息管理系统进行备案登记和DXPID申请。<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系统对接上线：<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业收到审批下来的DXPID后即可与综服平台进行系统调试与生产上线。综服平台为企业提供EXCEL导入、客户端对接、接口对接三种对接方式。选择EXCEL导入方式的企业可直接进入系统在申报页面下载模板导入完成申报，选择客户端或接口对接方式的企业请在“企业入驻-系统对接及资料下载”中下载相关对接文档。',
                }
			}
		},
        methods: {
            handleClick(tab, event) {
                switch (this.activeName){
                    case '01' :
                        this.oneJoin.title = '入驻指引'
                        this.oneJoin.text = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 大致流程：前置资质办理—综服平台用户注册—企业入驻—系统对接上线<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;前置资质办理：<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;完成海关注册登记、中国电子口岸IC卡办理、跨境电商企业类型备案等前置资质办理。（详见企业入驻-前置资质办理介绍）<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;综服平台用户注册:<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业可在综服平台用户登录界面，点击立即注册，提交企业开户信息完成平台用户注册。<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业入驻：<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业完成平台用户注册后，登录入驻信息管理系统进行备案登记和DXPID申请。<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系统对接上线：<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业收到审批下来的DXPID后即可与综服平台进行系统调试与生产上线。综服平台为企业提供EXCEL导入、客户端对接、接口对接三种对接方式。选择EXCEL导入方式的企业可直接进入系统在申报页面下载模板导入完成申报，选择客户端或接口对接方式的企业请在“企业入驻-系统对接及资料下载”中下载相关对接文档。'
                        break
                    case '02' :
                        this.oneJoin.title = '前置资质'
                        this.oneJoin.text = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.海关注册登记办理<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;跨境电子商务平台企业、物流企业、支付企业等参与跨境电子商务零售进口业务的企业，应当依据海关报关单位注册登记管理相关规定，向所在地海关办理注册登记，并在跨境电商企业类型中勾选相应的企业类型。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;海关注册登记步骤：登录中国（山东）国际贸易单一窗口进入企业资质系统向所在地海关提交备案申请，经所在地海关审核办结后，备案企业可自行打印备案回执，全程无纸化网上办理。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.中国电子口岸 IC 卡办理<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;计划开展跨境电商进出口业务的企业，在完成海关注册登记之后，需前往企业注册地中国电子口岸数据中心制卡中心办理中国电子口岸 IC 卡。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;咨询电话：青岛数据分中心0532-82955188<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;济南数据分中心0531-68696465<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.跨境电商企业类型备案<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;已完成海关注册登记的跨境电商企业，取得企业编码及中国电子口岸 IC 卡以后，可使用 IC 卡登陆中国（山东）国际贸易单一窗口进入企业资质系统，在海关企业通用资质—企业备案-变更申请中勾选“跨境贸易电子商务企业类型”完善其他必填信息后，保存提交变更申请。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注意：跨境贸易电子商务企业类型选择时，跨境电商出口业务的 电商企业可选择“电子商务企业”； 对于同时开展跨境电商零售和B2B业务的物流企业，企业类型应勾选“物流企业”；对于仅开展B2B业务的物流企业，企业类型应勾选“物流企业（仅B2B）”；监管场所运营人企业选择“监管场所运营人”。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.DXPID 申请<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DXPID为跨境电商报文传输编码，计划开展跨境电商业务的企业可登录跨境电商综服平台【企业入驻】—【入驻信息管理】点击左侧菜单“DXPID申请”按照页面提示提交DXPID申请信息及附件，注意：选择青岛关区的企业需提交《跨境电商统一版企业接入申请表》（扫描件）和《数据导入接口申请表》（EXCEL）；选择济南关区的企业需填写《跨境电商统一版企业接入申请表》、《海关预录入业务数据交换安全承诺书》、《企业法人授权书》、企业法人身份证扫描件，上述材料模板可在上传页面下载，ID 审批需要一周左右。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.出口海外仓企业备案<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如企业采用海关监管方式代码“9810”，全称“跨境电子商务出口海外仓”，则还应当进行海外仓备案。 具体如下：<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业资质条件：开展跨境电商出口海外仓业务的境内企业应已在海关办理注册登记，且企业信用等级为一般信用及以上。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;备案资料要求：两个登记表：《跨境电商出口海外仓企业备案登记表》《跨境电商海外仓信息登记表》（一仓一表）；<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;海外仓证明材料：海外仓所有权文件（自有海外仓）、海外仓租赁协议（租赁海外仓）、其他可证明海外仓使用的相关资料（如海外仓入库信息截图、海外仓货物境外线上销售相关信息）等；<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;海关认为需要的其他资料。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;上述资料应向企业主管地海关递交，如有变更，企业应及时向海关更新相关资料。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.担保金账户办理<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;海关对符合监管规定的跨境电子商务零售进口商品按时段汇总计征税款，代收代缴义务人应当依法向海关提交足额有效的税款担保。计划开展跨境电商零售进口业务的代收代缴义务人企业需办理担保金账户。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;代收代缴义务人申请办理税款担保时，需递交《担保申请表》等相关资料，主管海关进行核批。具体办理流程和所需资料以主管海关要求为准。'
                        break
                    case '03' :
                        window.open('https://app.singlewindow.cn/cas/login?service=https%3A%2F%2Fapp.singlewindow.cn%3A443%2Fcas%2Foauth2.0%2FcallbackAuthorize')
                        break
                    case '04' :
                        this.oneJoin.title = '系统对接'
                        this.oneJoin.text = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;一、账号和备案<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;打开跨境电商综合服务平台网站，点击首页功能模块【企业入驻】—【入驻信息管理】，在登录界面点击“立即注册”提交企业开户信息完成平台用户注册，平台用户注册后使用账号密码或者卡介质方式登录【企业入驻】—【入驻信息管理】完成综服平台备案。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;二、申请报文传输编码DXPID<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;登录【企业入驻】—【入驻信息管理】点击左侧菜单“DXPID申请”按照页面提示提交DXPID申请信息及附件，注意：选择青岛关区的企业需提交《跨境电商统一版企业接入申请表》（扫描件）和《数据导入接口申请表》（EXCEL）；选择济南关区的企业需填写《跨境电商统一版企业接入申请表》、《海关预录入业务数据交换安全承诺书》、《企业法人授权书》、企业法人身份证扫描件，上述材料模板可在上传页面下载。ID 审批需要一周左右。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;登录【企业入驻】—【入驻信息管理】查看DXPID审批结果，平台会自动将审批下来的DXPID进行生产参数配置，企业在查询页面查到DXPID后即可进行后续对接申报。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;三、对接申报<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平台为企业提供EXCEL导入、客户端、接口对接三种对接方式，对于单量比较小，还不具备报文生成技术的企业，建议使用EXCEL导入方式，对于单量比较大，但无自行加签能力的企业建议使用客户端方式，对于单量比较大，有技术开发能力，且有自行加签能力的企业建议使用接口对接方式。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用excel 导入方式申报的企业，登录跨境电商综服平台【业务应用中心】—【通关服务系统】根据企业业务类型进入相应的功能模块，点击“导入模板下载”，填写信息后导入系统完成加签即可。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;有报文生成系统的企业可选择客户端或者接口的方式与单一窗口进行对接，选择客户端对接的企业可在【企业入驻】—【下载中心】中下载客户端和客户端证书。安装和使用说明可参考《淄博跨境电商综合服务平台报文传输客户端操作手册》。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使 用 接 口 对 接 的 企 业 可 参 考 《Https+WebService 报文传输接口API 开发说明》按照接口规范进行对接。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;其他客户端及接口对接相关文档均可在【企业入驻】—【下载中心】中下载。<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;四、海关报文规范<br />' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请参考海关总署发布的《海关跨境电商统一版系统企业对接报文规范》（202205版），报文规范和报文样例可在【企业入驻】—【下载中心】中下载。'
                }
            }
        },
	}
</script>
<style lang="scss" scoped>
	.container {
		// overflow-x: hidden;

		.content {
			width: 100%;
			padding: 60px 0 30px 0;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			align-items: center;

			.title {
				font-size: 30px;
                font-weight: 600;
				color: #000;
                margin-top: 20px;
				margin-bottom: 20px;
			}

			.text {
				max-width: 1400px;
				font-size: 18px;
				line-height: 50px;
				padding: 0 100px;
			}
		}

		@media only screen and (max-width: 1620px) {}


		@media only screen and (max-width: 1360px) {}

		@media only screen and (max-width: 1100px) {}

		@media only screen and (max-width: 900px) {}

		@media only screen and (max-width: 800px) {}

		@media only screen and (max-width: 610px) {}

		@media only screen and (max-width: 500px) {
			.content {
				width: 100%;
				padding: 60px 0 30px 0;
				display: flex;
				flex-direction: column;
				// justify-content: center;
				align-items: center;

				.title {
					font-size: 18px;
					color: #000;
					margin-bottom: 20px;
					font-weight: 600;
				}

				.text {
					max-width: 1400px;
					font-size: 14px;
					line-height: 30px;
					padding: 0 50px;
				}
			}
		}
	}
</style>
