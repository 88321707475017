<template ref="page">
	<div class="container">
		<Nav></Nav>
		<div class="content">
            <div class="title">
                新闻资讯
            </div>
            <div class="stitle">
                News information
            </div>
            <div class="five-box" >
                <el-tabs v-model="activeName" @tab-click="tabClick()" style="width: 100%">
                    <el-tab-pane label="政策文件" name="02">
                    </el-tab-pane>
                    <el-tab-pane label="综试区动态" name="03">
                    </el-tab-pane>
                    <el-tab-pane label="通知公告" name="01">
                    </el-tab-pane>
                    <div class="five-item" v-if="homeNoticeList.length">
                        <el-row>
                            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="item in homeNoticeList" :key="item.noticeId" >
                                <el-col :xs="24" :sm="24" :md="9" :lg="6" :xl="6">
                                    <div class="five-center" v-if="item.noticeImg">
                                        <img :src="item.noticeImg" alt="" @click="clickNew(item)" >
                                    </div>
                                </el-col>
                                <el-col :span="24" :xs="24" :sm="24" :md="item.noticeImg ?  15 : 24" :lg="item.noticeImg ?  18 : 24" :xl="item.noticeImg ?  18 : 24">
                                    <div class="five-right" @click="clickNew(item)" >
                                        <div class="five-top">
                                            <div class="l"><a
                                                target="_blank">{{ item.noticeTitle }}</a>
                                            </div>
                                            <div class="time">{{ item.noticeDate }}</div>
                                        </div>
                                        <p>{{ item.noticeSubtitle }}
                                        </p>
                                    </div>
                                </el-col>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                <el-pagination
                                    @size-change="handleCurrentChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="queryForm.pageNum"
                                    :page-size="queryForm.pageSize"
                                    layout="prev, pager, next, jumper"
                                    :total="queryForm.total"
                                    background
                                >
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </div>
                    <div style="width: 100%;min-width: 80%; min-height: 450px;" v-else>
                        <p>暂无数据</p>
                    </div>
                </el-tabs>
            </div>
		</div>
		<Bottom></Bottom>
	</div>
</template>
<script>
	import Nav from '@/components/Nav.vue'
	import Bottom from '@/components/Bottom.vue'
    import {queryNoticeList} from "@/apis/notic";
	export default {
		components: {
			Nav,
			Bottom,
		},
		data() {
			return {
                activeName : '01',
                homeNoticeList : [],
                queryForm: {
                    noticeType : '01',
                    pageNum : 1,
                    pageSize : 10,
                    total : 0,
                },
			}
		},
        created() {
            this.activeName = this.$route.query.type
            this.getHomeNotice()
        },
        watch : {
            '$route.query.type'(newVal, oldVal){
                this.activeName = this.$route.query.type
                this.getHomeNotice()
            },
        },
        methods: {
            tabClick(){
                this.queryForm.pageNum = 1
                this.getHomeNotice()
            },
            
            getHomeNotice() {
                queryNoticeList({noticeType : this.activeName, pageNum : this.queryForm.pageNum, pageSize : this.queryForm.pageSize}).then((res) => {
                    this.homeNoticeList = res.data.rows
                    this.queryForm.total = res.data.total
                    console.log(this.homeNoticeList)
                })
            },
            clickNew(oneNew){
                localStorage.setItem('oneNew', JSON.stringify(oneNew))
                this.$router.push(`/oneNew`)
            },
            handleCurrentChange(val) {
                this.queryForm.pageNum = val
                this.getHomeNotice()
            }
        }
	}
</script>
<style lang="scss" scoped>
	.container {
		// overflow-x: hidden;

		.content {
			width: 80%;
            margin: auto;
			padding: 60px 0 30px 0;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			align-items: center;

			.title {
				font-size: 30px;
                font-weight: 600;
				color: #000;
				margin-bottom: 10px;
			}
            .stitle {
				font-size: 15px;
				color: #656567;
			}

			.text {
				max-width: 1400px;
				font-size: 18px;
				line-height: 50px;
				padding: 0 100px;

				div {
					text-indent: 2em;
				}
			}
            .five-box {
                width: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 32px;
                
                .five-item {
                    display: flex;
                    justify-content: center;
                    .five-center {
                        margin-top: 10px;
                        width: 100%;
                        max-width: 200px;
                        height: 200px;
                        
                        overflow: hidden;
                        cursor: pointer;
                        
                        img {
                            width: 100%;
                            max-width: 200px;
                            height: 200px;
                            object-fit: fill;
                            transition: all 1s;
                        }
                        
                        img:hover {
                            transform: translate(0px, 0px) scale(1.1) rotate(0deg);
                        }
                    }
                    
                    .five-right {
                        width: 100%;
                        min-height: 200px;
                        cursor: pointer;
                        .five-top {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 15px;
                            margin-top: 20px;
                            
                            .l {
                                width: 377px;
                                font-size: 20px;
                                font-weight: bold;
                                color: #313131;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                -o-text-overflow: ellipsis;
                                
                                a {
                                    color: black;
                                }
                            }
                            
                            .time {
                                font-size: 16px;
                                font-weight: 500;
                                color: #666666;
                            }
                        }
                        
                        p {
                            font-size: 16px;
                            font-weight: 500;
                            color: #666666;
                            line-height: 24px;
                        }
                    }
                }
            }
		}

		@media only screen and (max-width: 1620px) {}


		@media only screen and (max-width: 1360px) {}

		@media only screen and (max-width: 1100px) {}

		@media only screen and (max-width: 900px) {}

		@media only screen and (max-width: 800px) {}

		@media only screen and (max-width: 610px) {}

		@media only screen and (max-width: 500px) {
			.content {
				width: 100%;
				padding: 60px 0 30px 0;
				display: flex;
				flex-direction: column;
				// justify-content: center;
				align-items: center;

				.title {
					font-size: 18px;
					color: #000;
					margin-bottom: 20px;
					font-weight: 600;
				}

				.text {
					max-width: 1400px;
					font-size: 14px;
					line-height: 30px;
					padding: 0 50px;

					div {
						text-indent: 2em;
					}
				}
			}
		}
	}
</style>
