import {Md5} from 'ts-md5'
import {generateRandomID} from './randomId'

export function encryptionPlus(obj) {
    /* Long型时间戳 */
    const timestamp = new Date().getTime().toString();
    /* 32位随机数 */
    const nonce = generateRandomID(32)
    /* 盐key */
    const key = 'ApCErMGKjxStSmWRnWpiQVhjXaTPcBPF';

    const json = {
        'timestamp': timestamp,
        'nonce': nonce
    };
    Object.keys(obj).forEach(key => {
        if (key !== 'sign' || (obj[key] !== null && obj[key] !== '' && obj[key] !== 'undefined' && obj[key] !== 'UNDEFINED')) {
            json[key] = obj[key];
        }
    })

    /* 按照字母升序排序 */
    const jsonSort = jsonSortByKey(json);
    /* 要加密字符串 */
    let strObj = ''
    Object.keys(jsonSort).forEach(key => {
        strObj = strObj + key + '=' + jsonSort[key] + '&';
    });
    strObj = strObj + 'key=' + key;

    /* MD5加密 */
    const md5 = new Md5()
    md5.appendAsciiStr(strObj);
    const sign = md5.end();

    /* 处理后返回对象 */
    const returnObj = {
        'sign': sign,
        'timestamp': timestamp,
        'nonce': nonce
    };
    Object.keys(obj).forEach(key => {
        returnObj[key] = obj[key];
    });

    return returnObj;
}

/* 按照key字母排序 */
function jsonSortByKey(obj) {
    const sorted = {};
    Object.keys(obj)
        .sort()
        .forEach(key => {
            sorted[key] = obj[key];
        });
    return sorted;
}
