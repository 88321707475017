import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import News from "@/views/my/News.vue";
import oneNew from "@/views/my/oneNew.vue";
import JoinIn from "@/views/my/JoinIn.vue";

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	}, {
		path: '/us',
		name: 'us',
		component: () => import('../views/my/UsView.vue')
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('../views/my/ContactView.vue')
	},
	{
		path: '/news',
		name: 'news',
		component: News
	},
	{
		path: '/oneNew',
		name: 'oneNew',
		component: oneNew
	},
	{
		path: '/joinIn',
		name: 'joinIn',
		component: JoinIn
	}
]

const router = new VueRouter({
	routes
})

export default router
