import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';   //增加
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui';
import {
	Button,
	Select,
	Container,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Backtop,
	Carousel,
	CarouselItem,
	Row,
	Col,
	Tabs,
	TabPane

} from 'element-ui';
Vue.config.productionTip = false
Vue.use(Button)
Vue.use(Select)
Vue.use(Container)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Backtop)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Antd)
Vue.use(ElementUI)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
