<template>
  <div>
    <div class="top">
      <div class="left">你好！欢迎来到中国（滨州）跨境综试区公服平台</div>
      <div class="right"><span><a
          href="https://app.singlewindow.cn/cas/oauth2.0/authorize?client_id=sd00000000X4dW0P007&response_type=code&redirect_uri=https://www.singlewindow.sd.cn/pmc/reg/new/silentLogin?jumpTo=https://www.singlewindow.sd.cn/portal/delivery/cebdxp_bz"
          target="_blank"
          style="color: black;">登录</a></span> | <span><a
          href="https://www.singlewindow.sd.cn/pmc/reg/newUserReg/choseRegWay?zqId=bz"
          target="_blank" style="color: black;">注册</a></span></div>
    </div>
    <div class="nav" :class="scrollFixed === true ? 'isFixed' : ''">
      <div class="top">
        <img src="../assets/img/logo.png" alt="" class="limg">
        <img src="../assets/img/bj1.png" alt="" class="rimg">
      </div>
      <div class="bottom">
        <div class="ptitle">中国（滨州）跨境综试区公服平台</div>
        <ul class="flex pnav">
          <router-link :to="{path:'/'}">
            <li>首页</li>
          </router-link>
          <a href="https://wzf.bzkuajing.com/" target="_blank">
            <li>滨贸通</li>
          </a>
          <a href="https://kj.bzkuajing.com/" target="_blank">
            <li>滨滨跨境</li>
          </a>
          <a href="https://portal.bzbpd.com.cn:8002/#/index" target="_blank">
            <li>滨贸云链</li>
          </a>
          <a href="https://yp.bzkuajing.com/" target="_blank">
            <li>滨州优品</li>
          </a>
          <!-- <a href="#" target="_blank">
            <li>企业入驻</li>
          </a> -->
            <router-link :to="{path:'/joinIn'}" target="_blank">
                <li style="margin-right: 100px">入驻指引</li>
            </router-link>
          <router-link :to="{path:'/us'}" target="_blank">
            <li style="margin-right: 100px">平台介绍</li>
          </router-link>
          <router-link :to="{path:'/contact'}" target="_blank">
            <li style="margin-right: 0">联系我们</li>
          </router-link>
        </ul>
        <div class="nav-icon">
          <el-dropdown trigger="click">
						<span class="el-dropdown-link">
							<i class="el-icon-s-unfold"></i>
						</span>
            <el-dropdown-menu slot="dropdown" style="width: 90%">
              <router-link :to="{path:'/'}">
                <el-dropdown-item divided>首页</el-dropdown-item>
              </router-link>
              <a href="https://yp.bzkuajing.com/" target="_blank">
                <el-dropdown-item divided>滨州优品</el-dropdown-item>
              </a>
              <a href="https://kj.bzkuajing.com/" target="_blank">
                <el-dropdown-item divided>滨滨跨境</el-dropdown-item>
              </a>
              <a href="https://portal.bzbpd.com.cn:8002/#/index" target="_blank">
                <el-dropdown-item divided>滨贸云链</el-dropdown-item>
              </a>
              <a href="https://wzf.bzkuajing.com/" target="_blank">
                <el-dropdown-item divided>滨贸通</el-dropdown-item>
              </a>
              <!-- <el-dropdown-item divided>企业入驻</el-dropdown-item> -->
                <router-link :to="{path:'/joinIn'}" target="_blank">
                    <el-dropdown-item divided>入驻指引</el-dropdown-item>
                </router-link>
              <router-link :to="{path:'/us'}" target="_blank">
                <el-dropdown-item divided>平台介绍</el-dropdown-item>
              </router-link>
              <router-link :to="{path:'/contact'}" target="_blank">
                <el-dropdown-item divided>联系我们</el-dropdown-item>
              </router-link>
              <el-dropdown-item divided><a
                  href="https://app.singlewindow.cn/cas/oauth2.0/authorize?client_id=sd00000000X4dW0P007&response_type=code&redirect_uri=https://www.singlewindow.sd.cn/pmc/reg/new/silentLogin?jumpTo=https://www.singlewindow.sd.cn/portal/delivery/cebdxp_bz"
                  target="_blank"
                  style="color: black;">登录</a></el-dropdown-item>
              <el-dropdown-item divided><a
                  href="https://www.singlewindow.sd.cn/pmc/reg/newUserReg/choseRegWay?zqId=bz" target="_blank"
                  style="color: black;">注册</a></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {

      offsetTop: 0,
      scrollFixed: false,
      activeName: 'first'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    windowScroll() {
      // 滚动条顶部 距 滚动原点的高度
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      /**
       * 三目运算
       * 两个高度比较
       * 如果滑动距离 > 吸顶元素到页面顶端距离  动态添加
       */
      scrollTop > this.offsetTop ?
          (this.scrollFixed = true) :
          (this.scrollFixed = false);
    },
  },
  mounted() {
    this.clientHeight = `${document.documentElement.clientHeight}`; //获取浏览器可视区域高度
    // 需吸顶元素 距 离浏览器顶端的高度
    this.offsetTop = document.querySelector(".nav").offsetTop;
    // 滚动监听
    window.addEventListener("scroll", this.windowScroll);
  }
}
</script>
<style lang="scss" scoped>
.top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background: #F6F6F6;
  font-size: 12px;
  font-weight: 500;
  color: #666666;

  .left {
    margin-right: 963px;
  }

  .right {

    span:hover {
      cursor: pointer;
      color: #0C3A97;
    }
  }
}

.nav {
  .ptitle {
    display: none;
  }

  .top {
    height: 80px;
    background-color: #fff;

    .limg {
      margin-right: 154px;
    }
  }

  .bottom {

    height: 60px;
    display: flex;
    justify-content: center;
    background: #0C3A97;

    li {
      cursor: pointer;
      // padding: 0 20px;
      margin-right: 100px;
      font-size: 16px;
      padding-bottom: 3px;
      transition: all 1s;
    }

    li:hover {
      color: #f56c6c;
      box-shadow: 0px 2px 0px 0px #fff;
    }
  }

  .nav-icon {
    display: none;
  }
}

.isFixed {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 1);
  opacity: 1;


  .el-icon-s-unfold {
    font-size: 40px;
    color: #000;
  }

  a {
    color: #fff;
  }
}


@media only screen and (max-width: 1440px) {
  .top {
    .left {
      margin-right: 809px !important;
    }
  }

  .nav {
    .limg {
      margin-right: 0px !important;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .top {
    .left {
      margin-right: 600px !important;
    }
  }

  .nav {
    .limg {
      margin-right: 0px !important;
    }

    .rimg {
      display: none;
    }

    .bottom {
      li {
        margin-right: 50px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .top {
    display: none;

  }

  .nav {
    .ptitle {
      display: block;
    }

    .limg {
      margin-right: 0px !important;
    }

    .rimg {
      display: none;
    }

    .bottom {
      align-items: center;
      justify-content: space-around;

      li {
        margin-right: 50px;
      }

      .ptitle {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
      }

      .el-icon-s-unfold {
        font-size: 30px;
        color: #fff;
      }
    }

    .pnav {
      display: none;
    }

    .nav-icon {
      display: block;
    }
  }


}
</style>
